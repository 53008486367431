export enum FeatureKeys {
  UserPortal = "caapi_user_portal",
  Docs = "caapi_docs",
  Builder = "caapi_builder",
  DataSources = "caapi_data-sources",
  CohortBuilder = "caapi_cohort_builder",
  Ontology = "caapi_ontology",
  Eclair = "caapi_eclair",
  DeveloperAPIs = "caapi_apis",
}
