import { SiteNavLinkTabs } from "./SiteNavLinkTabs";
import UserProfileDropdown from "@components/UserProfile/UserProfileDropdown";
import config from "@config/config";
import { Navbar, NavbarComponentProps } from "flowbite-react";
import { NavLink, useLocation } from "react-router-dom";
import { LIGHT_LOGO, DARK_LOGO, SYMBOL_LOGO } from "@config/constants";
import { useWindowScroll } from "react-use";
import clsx from "clsx";
import { useState } from "react";

export type SiteHeaderProps = NavbarComponentProps;

export function SiteHeader(props: SiteHeaderProps) {
  const { pathname } = useLocation();
  const isHomePage = pathname === "/home";

  const { y } = useWindowScroll();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const dynamicBgClasses = isHomePage
    ? clsx({
        "bg-opacity-0 dark:bg-opacity-0": y < 30,
        "bg-opacity-30 dark:bg-opacity-30": y >= 30 && y < 60,
        "bg-opacity-50 dark:bg-opacity-50": y >= 60 && y < 80,
        "bg-opacity-70 dark:bg-opacity-70": y >= 80 && y < 100,
        "bg-opacity-100 dark:bg-opacity-100": y >= 100,
      })
    : "";

  return (
    <Navbar
      className={clsx(
        "bg-transparent top-0 z-50 px-2 py-2 sm:px-4 bg-white dark:bg-black transition-colors duration-200",
        {
          [dynamicBgClasses]: true,
          "fixed w-full": isHomePage,
          sticky: !isHomePage,
        },
      )}
      fluid
      {...props}
    >
      <Navbar.Brand
        as={NavLink}
        to={config.baseUrl}
        className="md:ml-0 md:mr-8"
      >
        <img
          className="md:hidden dark:hidden"
          src={SYMBOL_LOGO}
          alt="CarbonArc symbol"
          width="32"
          height="32"
          style={{
            filter: "drop-shadow(0 0 3px white)",
          }}
        />
        <img
          className="hidden md:block dark:hidden -mt-1"
          src={LIGHT_LOGO}
          alt="CarbonArc full logo"
          width="156"
          height="32"
          style={{
            filter: "drop-shadow(0 0 1px white)",
          }}
        />
        <img
          className="hidden dark:block"
          src={DARK_LOGO}
          alt="CarbonArc white logo"
          width="120"
          height="24"
          style={{
            filter: "drop-shadow(0 0 3px white)",
          }}
        />
      </Navbar.Brand>
      <div className="flex md:hidden items-center">
        <button
          className="p-2 text-gray-500 rounded-lg hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
          aria-label="Toggle menu"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          <svg
            className="w-6 h-6"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16m-7 6h7"
            ></path>
          </svg>
        </button>
      </div>
      <div
        className={clsx("flex-grow md:flex items-center gap-4", {
          hidden: !isMenuOpen,
          block: isMenuOpen,
        })}
      >
        <SiteNavLinkTabs />
      </div>
      <div className="flex items-center gap-4">
        <div className="flex gap-1 items-center">
          <UserProfileDropdown />
        </div>
      </div>
    </Navbar>
  );
}
