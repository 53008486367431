import { apiFetch } from "@/services/api";
import config from "@config/config";
import { TPaginationResult } from "@/types/data";
import { createQueryParams } from "@utils/url";

const USERS_BASE_URL = `${config.carcAuth.url}/users`;
const CLIENTS_BASE_URL = `${config.carcAuth.url}/clients`;

export enum ROLES {
  USER_UNRESTRICTED = "User Unrestricted",
  CLIENT_ADMIN = "Client Admin",
  CLIENT_LEGAL_ADMIN = "Client Legal Admin",
  VENDOR_ADMIN = "Vendor Admin",
}

export const adminRoles: `${ROLES}`[] = [
  ROLES.CLIENT_ADMIN,
  ROLES.CLIENT_LEGAL_ADMIN,
  ROLES.VENDOR_ADMIN,
];

export enum PERMISSIONS {
  INTERESTS = "interests",
  PASSWORD = "password",
  ALERTS_NOTIFICATIONS = "alerts_notifications",
  TOKENS = "tokens",
  TRANSACTIONS = "transactions",
  TEAM_PAGE = "team_page",
  USERS = "users",
  API_KEYS = "api_keys",
  BALANCE = "balance",
  WORKBOOKS = "workbooks",
}

export enum PERMISSIONS_RESTRICTION {
  ALL = "all",
  INDIVIDUAL = "individual",
  NO_LIMIT = "no_limit",
  USER_ONLY = "user_only",
  TEAM = "team",
  WITHIN_TEAM = "within_team",
}

export type TPermissionKeys = `${PERMISSIONS}`;

export type TUserRole = {
  id: string;
  role: `${ROLES}`;
  permissions: {
    [key in TPermissionKeys]?: {
      [key: string]: string[];
    };
  };
};

export interface TUserSelfInfo {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
}

export enum EntityType {
  EntityRepresentation = "entity_representation",
  Domain = "domain",
}

export type TUserInterest = {
  id: string;
  user_id: string;
  type: EntityType;
  data: {
    key: string;
    label: string;
  };
};

export interface TEntityRepresentationAndDomain {
  key: string;
  title: string;
  type: EntityType;
}

export interface TUpdateUserInterest {
  type: EntityType;
  label: string;
  data: {
    key: string;
    label: string;
  };
}

export interface TTeamUser {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  role: {
    id: string;
    name: string;
    description?: string;
  };
}

type TFetchTeamParams = {
  page: number;
  pageSize: number;
  filters?: Record<string, any>;
  sortBy?: string;
  sortOrder?: "asc" | "desc";
};

export interface TTeamUsers extends TPaginationResult {
  items: TTeamUser[];
}

export const fetchUsersApi = async ({
  page,
  pageSize,
  filters,
  sortBy = "",
  sortOrder = "asc",
}: TFetchTeamParams) => {
  return apiFetch<TTeamUsers>(
    `${CLIENTS_BASE_URL}/self/users?${createQueryParams({
      page,
      size: pageSize,
      filters: JSON.stringify(filters),
      sort_by: sortBy,
      sort_order: sortOrder,
    })}`,
  );
};

export const updateUserRole = async ({
  role_id,
  user_id,
}: {
  role_id: TTeamUser["role"]["id"];
  user_id: TTeamUser["id"];
}) => {
  return apiFetch(`${USERS_BASE_URL}/${user_id}/role`, {
    method: "PUT",
    body: JSON.stringify({ role_id }),
  });
};

export const fetchUserSelfRole = async () => {
  return apiFetch<TUserRole>(`${USERS_BASE_URL}/self/role`);
};

export const fetchUserSelfInfo = async () =>
  apiFetch<TUserSelfInfo>(`${USERS_BASE_URL}/self/info`);

export const fetchUserInterests = async () =>
  apiFetch<TUserInterest[]>(`${USERS_BASE_URL}/self/interests`);

export const updateUserInterests = async (updateData: {
  add?: Array<TUpdateUserInterest>;
  remove?: string[];
}) => {
  const response = await apiFetch(`${USERS_BASE_URL}/self/interests`, {
    method: "PATCH",
    body: JSON.stringify(updateData),
  });
  return response.data;
};

export const regenerateUserAPIToken = async () =>
  apiFetch(`${USERS_BASE_URL}/self/api-tokens/regenerate`, {
    method: "POST",
  });
