import { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { useFeatureFlagsStore } from "@stores/store";
import { fetchFeatureFlags } from "@services/cohort-builder";

export function useCohortBuilderFeatureAccess() {
  const setFeatureFlags = useFeatureFlagsStore(
    (state) => state.setFeatureFlags,
  );

  const audienceCohort = useQuery({
    queryKey: ["ab-feature-flags"],
    queryFn: async () => {
      return await fetchFeatureFlags();
    },
  });

  useEffect(() => {
    setFeatureFlags(audienceCohort.data);
  }, [audienceCohort.data]);

  return { audienceCohort };
}
