import { oktaAuth } from "@components/Okta";
import config from "@config/config";
import { buildURL } from "@utils/url";
import { useCallback, useMemo, useState } from "react";
import { useApiTokenStore } from "@stores/store";

const getOktaToken = async () => {
  try {
    return oktaAuth.getAccessToken();
  } catch (e) {
    throw new ApiError("No token found", 401);
  }
};

export const getToken = async (auth_type: "Okta" | "ApiToken" = "Okta") => {
  switch (auth_type) {
    case "Okta":
      return await getOktaToken();
    case "ApiToken":
      return useApiTokenStore.getState().apiToken;
    default:
      throw new ApiError("No token found", 401);
  }
};

export class ApiError extends Error {
  code: number;
  status: string;
  detail: string;

  constructor(message: string, code: number, detail?: string) {
    super(message);
    this.status = message;
    this.code = code;
    this.name = "ApiError";
    this.detail = detail || message;
  }
}

type CustomRequestInit = RequestInit & {
  multipart?: boolean;
};

const defaultCustomRequestInit: CustomRequestInit = {
  multipart: false,
};

export const rawApiFetch = async <TData = any>(
  url: string,
  options: RequestInit = {},
): Promise<TData> => {
  const finalUrl = buildURL(url, config.apiUrl);
  const response = await fetch(finalUrl, options);

  if (!response.ok) {
    if (response.body) {
      const body = await response.json();
      throw new ApiError(body.code, response.status, body?.detail);
    }
    throw new ApiError(response.statusText, response.status);
  }

  const contentType = response.headers.get("content-type");
  switch (contentType) {
    case "application/json":
      return response.json() as Promise<TData>;
    case "text/plain":
      return response.text() as Promise<TData>;
    default:
      return response.blob() as Promise<TData>;
  }
};

export const apiFetch = async <TData = any>(
  url: string,
  options: CustomRequestInit = {}, // Support CustomRequestInit for multipart
  auth_type: "Okta" | "ApiToken" = "Okta",
) => {
  const defaultHeaders: HeadersInit = {
    ...(options.multipart ? {} : { "Content-Type": "application/json" }),
  };
  defaultHeaders["Authorization"] = `Bearer ${await getToken(auth_type)}`;
  defaultHeaders["x-user-info"] = useApiTokenStore.getState().userInfo ?? "";
  const clientId = useApiTokenStore.getState().clientId;
  //Exclude the client_id if it’s unavailable, since we only obtain it after making the API call to retrieve user information.
  if (clientId) defaultHeaders["client_id"] = clientId;
  const mergedHeaders = { ...defaultHeaders, ...(options.headers || {}) };
  const mergedOptions = { ...options, headers: mergedHeaders };
  return rawApiFetch<TData>(url, mergedOptions);
};

export const apiDownload = (
  url: string,
  filename = "file",
  options: RequestInit = {},
  rawUrl = false,
): Promise<void> => {
  const defaultHeaders: HeadersInit = {};

  const mergedHeaders = { ...defaultHeaders, ...(options.headers || {}) };
  const mergedOptions = { ...options, headers: mergedHeaders };

  const finalUrl = rawUrl ? url : buildURL(url, config.apiUrl);

  return new Promise((resolve, reject) => {
    fetch(finalUrl, mergedOptions)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        a.remove();
        resolve();
      })
      .catch((error) => {
        console.log("Download error", error);
        reject(error);
      });
  });
};

export const authenticatedApiDownload = async (
  url: string,
  filename = "file",
  options: RequestInit = {},
  rawUrl = false,
  auth_type: "Okta" | "ApiToken" = "Okta",
): Promise<void> => {
  const defaultHeaders: HeadersInit = {
    Authorization: `Bearer ${await getToken(auth_type)}`,
  };

  return apiDownload(
    url,
    filename,
    { ...options, headers: { ...defaultHeaders, ...(options.headers || {}) } },
    rawUrl,
  );
};

export type ApiFetch = (url: string, options?: RequestInit) => Promise<any>;

export const useApiFetch = (): ApiFetch => {
  return useCallback((url: string, options: RequestInit = {}) => {
    return apiFetch(url, options);
  }, []);
};

export const useCancelableApiFetch = (): [
  <T>(url: string, options: RequestInit, timeout?: number) => Promise<T>,
  (reason?: string) => void,
] => {
  const apiFetch = useApiFetch();
  const [refreshController, setRefreshController] = useState(false);
  const controller = useMemo(() => new AbortController(), []);

  const cancelableApiFetch = useCallback(
    <T>(url: string, options: RequestInit, timeout = 120000): Promise<T> =>
      new Promise((resolve, reject) => {
        const timeoutId = setTimeout(() => {
          reject({ status: "timeout", message: "Request timed out" });
          controller.abort();
        }, timeout);
        apiFetch(url, {
          ...options,
          signal: controller.signal,
        })
          .then((response) => resolve(response as T))
          .catch((error) => {
            if (error.name === "AbortError") {
              reject({
                status: "abort",
                message: "Request aborted by the user",
              });
              setRefreshController(!refreshController);
            }
            reject(error);
          })
          .finally(() => clearTimeout(timeoutId));
      }),
    [apiFetch, controller, refreshController],
  );

  return [cancelableApiFetch, () => controller.abort()];
};

export const useApiDownload = () => {
  return useCallback(
    (
      url: string,
      filename = "file",
      options: RequestInit = {},
      rawUrl = false,
    ) => {
      return apiDownload(url, filename, options, rawUrl);
    },
    [],
  );
};

export const useApi = () => {
  const fetch: ApiFetch = useCallback(
    (url: string, options: RequestInit = {}) => {
      return apiFetch(url, options);
    },
    [],
  );

  const post = useCallback(
    (url: string, body: any, options: CustomRequestInit = {}) => {
      const fullOptions = { ...defaultCustomRequestInit, ...options };

      return apiFetch(url, {
        ...fullOptions,
        method: "POST",
        body: fullOptions.multipart ? body : JSON.stringify(body),
      });
    },
    [],
  );

  const remove = useCallback((url: string, options: RequestInit = {}) => {
    return apiFetch(url, { ...options, method: "DELETE" });
  }, []);

  return { fetch, post, remove };
};
