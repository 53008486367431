import { create } from "zustand";

type FeatureFlags = {
  featureFlags: [key: boolean | string | number] | NonNullable<unknown>;
  setFeatureFlags: (featureFlags: FeatureFlags) => void;
};

export const useFeatureFlagsStore = create<FeatureFlags>((set) => ({
  featureFlags: {},
  setFeatureFlags: (featureFlags: FeatureFlags) => set({ featureFlags }),
}));

type TUserInfoData = {
  clientUUID: string;
  clientId: string;
  userEmail: string;
  userUUID: string;
};

type ApiTokenState = {
  apiToken: string | null;
  clientId: string | null;
  userInfo: string | null;
  setApiToken: (data: { clientId: string; api_token: string }) => void;
  getApiToken: () => string | null;
  setUserInfo: (data: TUserInfoData) => void;
  getUserInfo: () => string | null;
  getClientId: () => string | null;
  doesApiTokenExist: () => boolean;
};

export const useApiTokenStore = create<ApiTokenState>((set, get) => ({
  apiToken: null,
  clientId: null,
  userInfo: null,

  setApiToken: ({ clientId, api_token }) => {
    set({ apiToken: api_token, clientId });
  },

  getApiToken: () => {
    return get().apiToken;
  },

  setUserInfo: (data) => {
    set({
      userInfo: btoa(
        JSON.stringify({
          client_uuid: data.clientUUID,
          client_id: data.clientId,
          user_email: data.userEmail,
          user_uuid: data.userUUID,
        }),
      ),
    });
  },

  getUserInfo: () => {
    return get().userInfo;
  },

  getClientId: () => {
    return get().clientId;
  },

  doesApiTokenExist: () => {
    return get().apiToken !== null;
  },
}));
