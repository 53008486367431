import { apiFetch } from "@services/api";
import { oktaAuth } from "@components/Okta";
import { hashObj } from "@components/CohortBuilder/Reports/reports.type";

const getFullUrl = (url: string) => {
  return import.meta.env.VITE_AB_BASE_URL + "v1/" + url;
};
const AUDIENCE_BUILDER_BASE_URL = getFullUrl("ab2");
const CAMPAIGN_MANAGER_BASE_URL = getFullUrl("campaign_manager");
const AUDIENCE_BUILDER_REPORTS_BASE_URL = getFullUrl("ads");
const S3_BASE_URL = getFullUrl("static");
const SETTINGS_BASE_URL = getFullUrl("settings");

export const getUserEmail = async (): Promise<string> => {
  return (await oktaAuth.getUser()).email ?? "";
};
export const fetchUserReports = async (type = "user_reports") =>
  apiFetch(`${AUDIENCE_BUILDER_BASE_URL}/reports/?reports_type=${type}`);

export const fetchPanels = async () =>
  apiFetch(
    `${AUDIENCE_BUILDER_BASE_URL}/panels/?username=${await getUserEmail()}`,
  );

export const CreateCustomQuery = async (name: string, query: string) => {
  const formData = new FormData();
  formData.append("name", name);
  formData.append("query", query);
  formData.append("username", await getUserEmail());
  return apiFetch(`${CAMPAIGN_MANAGER_BASE_URL}/custom_query/`, {
    method: "POST",
    body: formData,
    multipart: true,
  });
};

export const getEncryptionPublicKey = async () => {
  return apiFetch(`${CAMPAIGN_MANAGER_BASE_URL}/encryption/public-key/`, {
    method: "GET",
  });
};

export const createCustomCampaignReport = async (
  name: string,
  encryptedFileBase64: string,
  encryptionInfoBase64: string,
) => {
  const formData = new FormData();
  formData.append("encrypted_file_base64", encryptedFileBase64);
  formData.append("encryption_keys_base64", encryptionInfoBase64);
  formData.append("name", name);
  formData.append("username", await getUserEmail());

  return apiFetch(`${CAMPAIGN_MANAGER_BASE_URL}/create/`, {
    method: "POST",
    body: formData,
    multipart: true,
  });
};

export const createCampaignReport = async (reportId: string) => {
  return apiFetch(
    `${CAMPAIGN_MANAGER_BASE_URL}/create-campaign-by-report/?report_id=${reportId}`,
    {
      method: "POST",
    },
  );
};

export const fetchAB2FilterOptions = async (data: {
  filterKey: string;
  dependency?: string[][] | { [key: string]: any };
}) => {
  return apiFetch(
    `${AUDIENCE_BUILDER_BASE_URL}/filters/${data.filterKey}/options/?username=${await getUserEmail()}`,
    {
      method: "POST",
      body: JSON.stringify(data.dependency),
    },
  );
};

export const fetchAB2PanelByKey = async (panelKey: string) => {
  return apiFetch(
    `${AUDIENCE_BUILDER_BASE_URL}/panels/${panelKey}/?username=${await getUserEmail()}`,
    {
      method: "GET",
    },
  );
};

export const createAB2CustomQuery = async (
  name: string,
  panelType: string,
  filters: { [key: string]: any },
  additionalDetails: { [key: string]: any },
) => {
  return apiFetch(`${AUDIENCE_BUILDER_BASE_URL}/build-query/`, {
    method: "POST",
    body: JSON.stringify({
      panel_type: panelType,
      name: name,
      filters: filters,
      additional_details: additionalDetails,
    }),
  });
};

export const createReport = async (
  name: string,
  panelType: string,
  filters: { [key: string]: any },
  additionalDetails: { [key: string]: any },
) => {
  return apiFetch(
    `${AUDIENCE_BUILDER_BASE_URL}/create/?username=${await getUserEmail()}`,
    {
      method: "POST",
      body: JSON.stringify({
        panel_type: panelType,
        name: name,
        filters: filters,
        additional_details: additionalDetails,
      }),
    },
  );
};

export const deleteUserReport = async (id: string) => {
  return apiFetch(`${AUDIENCE_BUILDER_BASE_URL}/reports/${id}/`, {
    method: "DELETE",
  });
};

export const fetchReportInfo = async (reportId: string) =>
  apiFetch(`${AUDIENCE_BUILDER_BASE_URL}/reports/${reportId}/view/`);

export const fetchChartsConfig = async () =>
  apiFetch(`${AUDIENCE_BUILDER_BASE_URL}/reports/view/config/`);

export const fetchChartData = async (reportId: string, chartUrl: string) =>
  apiFetch(`${AUDIENCE_BUILDER_BASE_URL}/reports/${reportId}${chartUrl}`);

export const fetchAudienceCount = async (panelType: string, filters: hashObj) =>
  apiFetch(
    `${AUDIENCE_BUILDER_BASE_URL}/audience_count/?username=${await getUserEmail()}`,

    {
      method: "POST",
      body: JSON.stringify({
        panel_type: panelType,
        filters: filters,
      }),
      headers: { "Content-Type": "application/json" },
    },
  );

export const uploadAudiences = async (platform: string, reportId: string) => {
  return apiFetch(
    `${AUDIENCE_BUILDER_REPORTS_BASE_URL}/platforms/${platform}/reports/${reportId}/upload_audiences/`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    },
  );
};

export const requestAudiences = async (platform: string, reportId: string) => {
  return apiFetch(
    `${AUDIENCE_BUILDER_REPORTS_BASE_URL}/platforms/${platform}/reports/${reportId}/request_audiences/`,
    {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    },
  );
};

export const fetchReportFile = async (
  bucket: string,
  fileKey: string,
  responseContentType?: string,
) =>
  apiFetch(
    `${S3_BASE_URL}/s3/${bucket}/filekey/${fileKey}/?response_content_type=${responseContentType || ""}`,
  );

export const fetchFeatureFlags = async () =>
  apiFetch(
    `${SETTINGS_BASE_URL}/featureflags/?username=${await getUserEmail()}`,
  );
