import { useCallback } from "react";
import { fetchUserFeatureAccess } from "@services/auth";
import { useQuery } from "@tanstack/react-query";
import { FeatureKeys } from "@config/features";

export function useFeatureAccess() {
  const {
    data: featureKeys = [],
    isLoading,
    isError,
    isFetched,
  } = useQuery<string[]>({
    queryKey: ["user-feature-access"],
    queryFn: fetchUserFeatureAccess,
  });

  const canAccessFeature = useCallback(
    (feature: FeatureKeys) => {
      return featureKeys.includes(feature);
    },
    [featureKeys],
  );

  const hasAnyAccess = useCallback(
    (features: FeatureKeys[]) => {
      return features.some((feature) => featureKeys.includes(feature));
    },
    [featureKeys],
  );

  const hasAllAccess = useCallback(
    (features: FeatureKeys[]) => {
      return features.every((feature) => featureKeys.includes(feature));
    },
    [featureKeys],
  );

  return {
    canAccessFeature,
    hasAnyAccess,
    hasAllAccess,
    isLoading,
    isError,
    isFetched,
  };
}
