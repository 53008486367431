import React from "react";
import { getTheme, Tooltip, TooltipProps } from "flowbite-react";
import { useFeatureAccess } from "@hooks/useFeatureAccess";
import { FeatureKeys } from "@config/features";
import { twMerge } from "tailwind-merge";

interface ConditionalAccessProps {
  feature?: FeatureKeys;
  children: React.ReactNode;
  tooltipMessage?: string;
  className?: string;
  tooltipProps?: Partial<TooltipProps>;
}

export function ConditionalAccess({
  feature,
  children,
  tooltipMessage = "You do not have access to this feature.",
  className,
  tooltipProps = {},
}: ConditionalAccessProps) {
  const { canAccessFeature } = useFeatureAccess();
  const hasAccess = !feature || canAccessFeature(feature);
  const tooltipTheme = getTheme().tooltip;

  return hasAccess ? (
    <>{children}</>
  ) : (
    <Tooltip
      content={tooltipMessage}
      theme={{
        ...tooltipTheme,
        target: twMerge(tooltipTheme.target, className),
      }}
      {...tooltipProps}
    >
      <div className={`opacity-50 pointer-events-none cursor-not-allowed`}>
        {children}
      </div>
    </Tooltip>
  );
}
