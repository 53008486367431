import UserAvatar from "../UserAvatar";
import React from "react";

interface IProfileHeaderProps {
  userName: string | null;
  toggleDropdown: () => void;
}

const ProfileHeader: React.FC<IProfileHeaderProps> = ({
  userName,
  toggleDropdown,
}) => (
  <div className="flex items-center cursor-pointer" onClick={toggleDropdown}>
    {/* TODO: improve mobile responsiveness across the app */}
    <span className="font-medium text-ca-premiumGray dark:text-white mr-2 [@media(max-width:1062px)]:hidden">
      {userName}
    </span>
    <UserAvatar size="small" />
  </div>
);

export default ProfileHeader;
