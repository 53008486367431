import { pageData } from "@config/pages";
import { BaseLayout } from "@layouts/BaseLayout";
import { useOktaAuth } from "@okta/okta-react";
import Root from "@routes/root";
import { setLastVisited } from "@stores/lastVisited";
import { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useFeatureAccess } from "@hooks/useFeatureAccess";

export const ProtectedRouter = () => {
  const { authState } = useOktaAuth();
  const location = useLocation();
  const { hasAllAccess } = useFeatureAccess();

  useEffect(() => {
    // if user got logged out, record the last visited page
    if (authState && !authState.isAuthenticated) {
      console.log("last", location.pathname);
      setLastVisited(
        location.pathname +
          "?" +
          new URLSearchParams(location.search).toString(),
      );
    }
    // removed location from dependencies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authState]);

  const accessiblePages = Object.values(pageData).filter(({ fAccessKeys }) =>
    hasAllAccess(fAccessKeys || []),
  );

  return (
    <Routes>
      <Route element={<Root />}>
        <Route element={<BaseLayout />}>
          <Route path="/" element={<Navigate to={pageData.home.path} />} />
          {accessiblePages.map(({ path, component: Component }) => {
            return <Route key={path} path={path} element={<Component />} />;
          })}
          <Route path="*" element={<>Not found</>} />
        </Route>
      </Route>
    </Routes>
  );
};
